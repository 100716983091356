import { Injectable } from '@angular/core';
import {Observable, BehaviorSubject, of} from 'rxjs';
import { IHeureParam } from 'src/app/interface/heureParam.interface';
import { RestClientService } from '../rest-client.service';
import { UserService } from '../user/user.service';
import { HttpHeaders } from '@angular/common/http';
import dayjs from 'dayjs/esm';
import {IApiResponse} from "../../interface/api-response.interface";
import {DashboardKPI, PdvPointsKPI} from "../../types/dashboard-kpi.type";
import {DateHourFilterParam} from "../../types/filter-param.type";
import {AGENT_WITH_LAST_DATE_REQUEST_DEFAULT_BODY} from "../../types/agent";
import {UtilitiesService} from "../utilities/utilities.service";
import {UtilsService} from "@synelia/utils";

//TODO remove this when dashboardFilterDataService will be correctly sync with last stock dates datas
export interface FilterDate {
  date: string;
  hour: string;
  fulldate: Date;
}
//TODO remove this when dashboardFilterDataService will be correctly sync with last stock dates datas
export interface FilterDates {
  start?: FilterDate;
  end?: FilterDate
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private readonly basePath: string = 'dashbord'
  currentDate = new Date();
  //TODO remove this when dashboardFilterDataService will be correctly sync with last stock dates datas
  currentStockDate = new BehaviorSubject<FilterDate>({
    fulldate: this.currentDate,
    date: `${this.currentDate.getDay()}/${this.currentDate.getMonth()}/${this.currentDate.getFullYear()}`,
    hour: `${this.currentDate.getHours()}:${this.currentDate.getMinutes()}:${this.currentDate.getDay()}`
  })
  //TODO remove this when dashboardFilterDataService will be correctly sync with last stock dates datas
  filterDates = new BehaviorSubject<FilterDates>({})

  today = this.currentDate.getDate() + '/' + (this.currentDate.getMonth() + 1) + '/' + this.currentDate.getFullYear()
  private readonly defaultKPIRequestBodyDatas = {
    isLastDate: false,
    data: {
      dateDebut: "",
      dateFin: "",
      heureParam: {
        start: "",
        end: ""
      },
      regions: [] as string[],
      region: ""
    }
  }
  private readonly territoireKPIRequestBodyDatas = {
    "isLastDate": false,
    "data": {
      "dateDebut": "",
      "dateFin": "",
      "heureParam": {
        "start": "",
        "end": ""
      },
      "regionId": ""
    }
  }
  private commonBody = {
    "user": "",
    "isLastDate": false,
    "data": {
      "dateDebut": "",
      "dateFin": "",
      "heureParam": {
        "start": "",
        "end": ""
      },
      "isActif30j": true,
      "regions": [] as string[] | 'all',
      "territoires": [] as string[] | 'all',
      "distributeurs": [] as string[] | 'all'
    }
  }
  private distributeurRuptureData = {
    user: "",
    index: 0,
    size: 20,
    isLastDate: false,
    "data": {
      "orderField": "FULL_DATE",
      "orderDirection": "DESC",
      isActif30j: false,
      "date_param": {
        "operator": "[]",
        "start": "",
        "end": ""
      },
      "heure_param": {
        "operator": "[]",
        "start": "",
        "end": ""
      }
    }
  }
  private nombreRuptureBody = {
    "user": "",
    "isLastDate": false,
    "data": {
      "dateDebut": "",
      "dateFin": "",
      "heureParam": {
        "start": "",
        "end": ""
      },
      "isActif30j": true,
      "regions": [] as string[] | 'all',
      "territoires": [] as string[] | 'all',
      "distributeurs": [] as string[] | 'all'
    }
  }
  private commonRequestBody = {...AGENT_WITH_LAST_DATE_REQUEST_DEFAULT_BODY}
  constructor(
    private restClient: RestClientService,
    private user: UserService,
    private utilitiesService: UtilitiesService,
    private readonly utilsService: UtilsService
  ) { }

  setDateHourParam(payload: DateHourFilterParam | null){
    this.commonRequestBody = {
      ...this.utilitiesService.setDateHourParamWithLastDate(AGENT_WITH_LAST_DATE_REQUEST_DEFAULT_BODY, payload)
    }
  }

  getKPI(
    startDate: string = this.today,
    endDate: string = this.today,
    startHour: string,
    endHour: string,
    isLastDate: boolean = false,
    region: string = ''
  ): Observable<IApiResponse<DashboardKPI>> {

    const requestBody = {...this.defaultKPIRequestBodyDatas}

    requestBody.isLastDate = isLastDate
    if (!isLastDate) {
      requestBody.data.dateDebut = startDate
      requestBody.data.dateFin = endDate
      requestBody.data.heureParam.start = startHour
      requestBody.data.heureParam.end = endHour
    }

    requestBody.data.regions = region.length > 0 ? [region.toString()] : []
    requestBody.data.region = region.length > 0 ? region.toString() : ''

    return this.restClient.post(`${this.basePath}/getNiveauStockUv`, requestBody)
  }

  getRegionKPI(
    startDate: string = this.today,
    endDate: string = this.today,
    lastHour: IHeureParam,
    isLastDate: boolean = false,
    mapType: 'taux_rupture' | 'dashboard' = 'dashboard',
    regions?: string[]
  ): Observable<IApiResponse<DashboardKPI>> {
    const bodyDatas = JSON.parse(JSON.stringify(this.defaultKPIRequestBodyDatas))
    if (regions && regions.length > 0) bodyDatas.data.regions = [...bodyDatas.data.regions, ...regions]
    bodyDatas.isLastDate = isLastDate
    if (!isLastDate) {
      bodyDatas.data.dateDebut = startDate
      bodyDatas.data.dateFin = endDate
      bodyDatas.data.heureParam = lastHour
    }
    const requestURL = mapType === 'dashboard' ? `${this.basePath}/getNiveauStockUvByRegion` : `${this.basePath}/getInfosTauxRuptureByRegion`
    return this.restClient.post(requestURL, bodyDatas)
  }

  getTerritoireKPIByRegion(
    startDate: string = this.today,
    endDate: string = this.today,
    lastHour: IHeureParam,
    isLastDate: boolean = false,
    region: number | string,
    mapType: 'taux_rupture' | 'dashboard' = 'dashboard',
  ): Observable<IApiResponse<DashboardKPI>> {
    const bodyDatas = JSON.parse(JSON.stringify(this.territoireKPIRequestBodyDatas))
    if (region.toString().length > 0) {
      bodyDatas.data = {
        ...bodyDatas.data,
        [mapType === 'dashboard' ? 'regionId' : 'region']: region.toString()
      }
    }

    bodyDatas.isLastDate = isLastDate
    if (!isLastDate) {
      bodyDatas.data.dateDebut = startDate
      bodyDatas.data.dateFin = endDate
      bodyDatas.data.heureParam = lastHour
    }

    const requestURL = mapType === 'dashboard' ? `${this.basePath}/getNiveauStockUvRegionByTerritoir` : `${this.basePath}/getInfosTauxRuptureByTerritoir`
    return this.restClient.post(requestURL, bodyDatas)
  }

  /**
   * get last time of stock from api and emit it for the this.currentStockDate subscribers
   * @returns
   */
  //TODO remove this when dashboardFilterDataService will be correctly sync with last stock dates datas
  getStockLastTime() {
    const bodyDatas = {
      "data": {}
    }
    return this.restClient.post<{
      items: {
        adresse_GEOGRAPHIQUE: string,
        date: string,
        dis_EN_RUPTURE: boolean,
        full_DATE: string,
        grade_DISTRIBUTEUR: string,
        heure: string,
        nom_DISTRIBUTEUR: string,
        numero_DISTRIBUTEUR: string,
        prenom_DISTRIBUTEUR: string,
        region: string,
        seuil_DIST: number,
        stock_DISTRIBUTEUR: number,
        territoire: string,
        value: number,
        valuePdv: number
      }[]
    }>('omerDistHist/getLastInfoIndex', bodyDatas).subscribe({
      next: ({ items }) => {
        const item = items[0]
        const filterDate = {
          date: item.date,
          hour: item.heure,
          fulldate: new Date(`${item.date} ${item.heure}`),
        }

        this.currentStockDate.next(filterDate)

        this.filterDates.next({
          start: filterDate,
          end: filterDate
        })
      }
    })
  }

  searchPdvByDistributors(
    term: string,
    distributors: string[] | 'all',
    isActivethirtyDaysFilter: boolean
  ) {
    let bodyDatas: {
      data: {
        orderField: string;
        orderDirection: string;
        isActif30j: boolean;
        numero_DISTRIBUTEUR_param?: {
          operator: string;
          datas: string[] | 'all';
        };
        msisdn?: string;
        msisdn_param?: {
          operator: string,
          datas?: string[] | 'all'
        };
      }
    } = {
      data: {
        orderField: "FULL_DATE",
        orderDirection: "DESC",
        isActif30j: isActivethirtyDaysFilter
      }
    }
    if (distributors.length > 0) {
      bodyDatas.data.numero_DISTRIBUTEUR_param = {
        operator: "IN",
        datas: distributors
      }
    }
    if (term != '') {
      bodyDatas.data.msisdn = term
      bodyDatas.data.msisdn_param = {
        operator: "%%",
      }
    }
    return this.restClient.post<{
      hasError: boolean,
      sumTotalDistributeur: number,
      sumTotalPdv: number,
      sumTotalDistributeurPlain: string,
      sumTotalPdvPlain: string
    }>('dashbord/getListPdv', bodyDatas)
  }

  exportPdvData(
    endpoint: string = '',
    startDate: string = '',
    endDate: string = '',
    hour: { start: string, end: string },
    isLastDate: boolean = false,
    isLastMonth: boolean = false,
    index: number = 0, size: number = 20,
    geographicalFilterParams: { regions: Array<string> | 'all', territoires: Array<string> | 'all', distributeurs: Array<string> | 'all' }
  ) {
    const bodyDatas = { ...this.commonBody }
    bodyDatas.user = this.user.userMatricule
    bodyDatas.isLastDate = isLastDate
    Object.assign(bodyDatas, { size: size, index: index })
    if (!isLastDate) {
      bodyDatas.data.dateDebut = startDate
      bodyDatas.data.dateFin = endDate
      bodyDatas.data.heureParam = hour
    }

    let httpOptions = { responseType: 'arraybuffer', observe: 'response' }
    bodyDatas.data.isActif30j = isLastMonth
    bodyDatas.data.regions = geographicalFilterParams.regions
    bodyDatas.data.territoires = geographicalFilterParams.territoires
    bodyDatas.data.distributeurs = geographicalFilterParams.distributeurs
    return this.restClient.postExport(endpoint, bodyDatas)
  }

  exportDistributeurRuptureData(
    endpoint: string = '',
    startDate: string = '',
    endDate: string = '',
    hour: { start: string, end: string },
    isLastDate: boolean = false,
    isLastMonth: boolean = false,
    index: number = 0, size: number = 20,
    searchParam: string = "",
    geographicalFilterParams: { regions: Array<string> | 'all', territoires: Array<string> | 'all', distributeurs: Array<string> | 'all' }
  ) {
    const bodyDatas = { ...this.distributeurRuptureData }
    bodyDatas.isLastDate = isLastDate
    bodyDatas.size = size
    bodyDatas.index = index
    bodyDatas.data.date_param.start = dayjs(startDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    bodyDatas.data.date_param.end = dayjs(endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
    bodyDatas.data.heure_param.start = hour.start
    bodyDatas.data.heure_param.end = hour.end

    let httpOptions = { responseType: 'arraybuffer', observe: 'response' }
    bodyDatas.data.isActif30j = isLastMonth

    if (geographicalFilterParams.regions.length !== 0) {
      Object.assign(bodyDatas.data, {
        region_param: {
          "operator": "IN",
          "datas": geographicalFilterParams.regions
        }
      })
    }
    if (geographicalFilterParams.territoires.length !== 0) {
      Object.assign(bodyDatas.data, {
        territoire_param: {
          "operator": "IN",
          "datas": geographicalFilterParams.territoires
        }
      })
    }
    if (geographicalFilterParams.distributeurs.length !== 0) {
      Object.assign(bodyDatas.data, {
        numero_DISTRIBUTEUR_param: {
          "operator": "IN",
          "datas": geographicalFilterParams.distributeurs
        }
      })
    }
    if (searchParam.length !== 0) Object.assign(bodyDatas.data, { numero_DISTRIBUTEUR: searchParam })
    return this.restClient.postExport(endpoint, bodyDatas)
  }

  getRegionStock(startDate: string = '', endDate: string = '', hour: { start: string, end: string }, isLastDate: boolean = false, isLastMonth: boolean = false, geographicalFilterParams: { regions: Array<string> | 'all', territoires: Array<string> | 'all', distributeurs: Array<string> | 'all' }) {
    const bodyDatas = { ...this.nombreRuptureBody }
    bodyDatas.isLastDate = isLastDate
    if (!isLastDate) {
      bodyDatas.data.dateDebut = startDate
      bodyDatas.data.dateFin = endDate
      bodyDatas.data.heureParam = hour
    }
    bodyDatas.data.isActif30j = isLastMonth
    bodyDatas.data.regions = geographicalFilterParams.regions
    bodyDatas.data.territoires = geographicalFilterParams.territoires
    bodyDatas.data.distributeurs = geographicalFilterParams.distributeurs
    return this.restClient.post('dashbord/totalRegion', bodyDatas)
  }

  getTerritoireStock(startDate: string = '', endDate: string = '', hour: { start: string, end: string }, isLastDate: boolean = false, isLastMonth: boolean = false, geographicalFilterParams: { regions: Array<string> | 'all', territoires: Array<string> | 'all', distributeurs: Array<string> | 'all' }) {
    const bodyDatas = { ...this.nombreRuptureBody }
    bodyDatas.isLastDate = isLastDate
    if (!isLastDate) {
      bodyDatas.data.dateDebut = startDate
      bodyDatas.data.dateFin = endDate
      bodyDatas.data.heureParam = hour
    }
    bodyDatas.data.isActif30j = isLastMonth
    bodyDatas.data.regions = geographicalFilterParams.regions
    bodyDatas.data.territoires = geographicalFilterParams.territoires.length === 0 ? [] : geographicalFilterParams.territoires
    bodyDatas.data.distributeurs = geographicalFilterParams.distributeurs.length === 0 ? [] : geographicalFilterParams.distributeurs
    return this.restClient.post('dashbord/totalTerritoire', bodyDatas)
  }

  getDistributeurStock(startDate: string = '', endDate: string = '', hour: { start: string, end: string }, isLastDate: boolean = false, isLastMonth: boolean = false, geographicalFilterParams: { regions: Array<string> | 'all', territoires: Array<string> | 'all', distributeurs: Array<string> | 'all' }) {
    const bodyDatas = { ...this.nombreRuptureBody }
    bodyDatas.isLastDate = isLastDate
    if (!isLastDate) {
      bodyDatas.data.dateDebut = startDate
      bodyDatas.data.dateFin = endDate
      bodyDatas.data.heureParam = hour
    }
    bodyDatas.data.isActif30j = isLastMonth
    bodyDatas.data.regions = geographicalFilterParams.regions
    bodyDatas.data.territoires = geographicalFilterParams.territoires
    bodyDatas.data.distributeurs = geographicalFilterParams.distributeurs
    return this.restClient.post('dashbord/totalDistributeur', bodyDatas)
  }

  getUVSupplyVolumeKPI(){
    return this.restClient.post('omerDistHist/getApprovisionnementUv', this.commonRequestBody)
  }

  getPDVPointsKPI(){
    const requestBody = {...this.commonRequestBody}
    Object.assign(requestBody, {
      data: {
        date_param: {
          operator: "[]",
          start: this.utilsService.transformDateIntoEnglishFormat(requestBody.data.dateDebut),
          end: this.utilsService.transformDateIntoEnglishFormat(requestBody.data.dateFin)
        },
        heure_param: {
          operator: "[]",
          start: requestBody.data.heureParam.start,
          end: requestBody.data.heureParam.end
        }
      }
    })

    return this.restClient.post<IApiResponse<PdvPointsKPI> & {item: PdvPointsKPI}>('dashbord/getAllCommissions', requestBody)
  }
}
