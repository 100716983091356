import * as i0 from '@angular/core';
import { Injectable, Component } from '@angular/core';
class UtilsService {
  constructor() {}
  generateRandomString(length = 16) {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    const randomArray = new Uint8Array(length);
    crypto.getRandomValues(randomArray);
    randomArray.forEach(number => {
      result += chars[number % chars.length];
    });
    return result;
  }
  transformDateIntoEnglishFormat(dateStr) {
    const [day, month, year] = dateStr.split(/[-\/]/);
    return `${year}-${month}-${day}`;
  }
  isPercentageValue(value) {
    return value ? value.includes('%') : false;
  }
  filterNonEmptyValues(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([key, value]) => value !== null && value !== '' && value !== undefined));
  }
  transformStringToNumber(str) {
    return Number(str.trim().replace(/[\s,]/g, ""));
  }
  toFrenchMoneyFormat(value) {
    if (typeof value === "string") {
      value = this.transformStringToNumber(value);
    }
    return new Intl.NumberFormat('fr-FR').format(value);
  }
  static ɵfac = function UtilsService_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || UtilsService)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: UtilsService,
    factory: UtilsService.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UtilsService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
class UtilsComponent {
  static ɵfac = function UtilsComponent_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || UtilsComponent)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: UtilsComponent,
    selectors: [["lib-utils"]],
    standalone: true,
    features: [i0.ɵɵStandaloneFeature],
    decls: 2,
    vars: 0,
    template: function UtilsComponent_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "p");
        i0.ɵɵtext(1, " utils works! ");
        i0.ɵɵelementEnd();
      }
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UtilsComponent, [{
    type: Component,
    args: [{
      selector: 'lib-utils',
      standalone: true,
      imports: [],
      template: `
    <p>
      utils works!
    </p>
  `
    }]
  }], null, null);
})();

/*
 * Public API Surface of utils
 */

/**
 * Generated bundle index. Do not edit.
 */

export { UtilsComponent, UtilsService };
