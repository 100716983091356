import {DataGlobalFiltersParams, DateFilterParam, FiltersParams} from "./filter-param.type";

export type AgentSegmentKPI = {
  key: string
  valueCurrent: number
  valueCurrentPlain: string
  valuePredict: number
  valuePredictPlain: string
}

export type AgentTerritoryData = {
  libelle: string
  nbrAgent: string
  name?: string
  periode?: string
}

export type AgentRegionChartData = {
  percent: string
  region: string
  value: number
}

export type AgentRanking = {
  grade_AGENT: string
  libelle: string
  name: string
  totalCommission: string
}

export type CommissionRanking = {
  key: string
  nom_AGENT?: string
  prenom_AGENT?: string
  nom_DISTRIBUTEUR?: string
  prenom_DISTRIBUTEUR?: string
  value: string
  ratio: string
  ratioPercent: string
  isIncrease: boolean
}

export type AgentInactivityItem = {
  key: string
  keyValue?: string
  value1jr: number
  value1jrPercent: string
  value1jrPlain: string
  value30jr: number
  value30jrPercent: string
  value30jrPlain: string
  value7jr: number
  value7jrPercent: string
  value7jrPlain: string
}

export type CommissionItem = {
  key: string
  keyValue?: string
  value: number
  valueCommissionDist: number
  valueCommissionDistPercent: string
  valueCommissionDistPlain?: string
  valuePercent: string
  valuePlain: string
}

export enum ShowAllType {
  REGIONS= 'REGIONS',
  TERRITORIES = 'TERRITORIES',
  LOCALITIES = 'LOCALITIES',
  DEALERS = 'DEALERS',
  PDVS = 'PDVS',
}

export type CommissionDataType = ShowAllType | 'BEST_AGENT' | 'BEST_DEALER'

export type AgentWithLastDateCommonRequestBody = {
  isLastDate: boolean;
  data: {
    dateDebut: string;
    dateFin: string;
    heureParam: {
      start: string;
      end: string;
    },
    categorie_DISTRIBUTEUR_param?: {
      datas: string[],
      operator: string
    },
    categorie_PDV_param?: {
      datas: string[],
      operator: string
    }
  } & Partial<Omit<FiltersParams, 'localities' | 'categorie_DISTRIBUTEUR_param' | 'categorie_PDV_param' > & {localites: string[] | 'all'}>;
}

export const DATA_FILTERS_PARAMS = {
  regions: [],
  territories: [],
  localities: [],
  distributors: [],
  pdvs: [],
  categorie_DISTRIBUTEUR_param: [],
  categorie_PDV_param: [],
  thirtyDays: false
} as FiltersParams

export const DATA_FILTERS_DEFAULT_PARAMS = {
  regions: [],
  territories: [],
  localities: [],
  distributors: [],
  pdvs: [],
  thirtyDays: false
} as Omit<FiltersParams, 'categorie_DISTRIBUTEUR_param' | 'categorie_PDV_param'> & {
  categorie_DISTRIBUTEUR_param?: {
    datas: string[],
    operator: string
  },
  categorie_PDV_param?: {
    datas: string[],
    operator: string
  }
}

export const DATA_GLOBAL_FILTERS_PARAMS = {
  "categorie_DISTRIBUTEUR_param": {
    "datas": [],
    "operator": "IN"
  },
  "categorie_PDV_param": {
    "datas": [],
    "operator": "IN"
  },
  "msisdn_param": {
    "datas": [],
    "operator": "IN"
  },
  "numero_DISTRIBUTEUR_param": {
    "datas": [],
    "operator": "IN"
  },
  "isActif30j": false,
  "isAllDistributeurs": false,
  "isAllLocalites": false,
  "isAllPdvs": false,
  "isAllRegions": false,
  "isAllTerritoires": false,
  "region_param": {
    "datas": [],
    "operator": "IN"
  },
  "territoire_param": {
    "datas": [],
    "operator": "IN"
  }
} as DataGlobalFiltersParams

export type AgentCommonRequestBody = {
  data: {
    date_param: DateFilterParam
    heure_param: DateFilterParam
    key: string
  } & Partial<DataGlobalFiltersParams>
}

export type AgentModalShowAllTableDataAdditionalInformations = {key: string; title: string}
export type RankCategory = 'gold'|'silver'|'bronze'
export type RankCategoryText = 'Or'|'Argent'|'Bronze'
export type Ranking = {rankClass: RankCategory | string, icon: string, rank: string, fullname: string, msidn: string, category: RankCategoryText, totalCommission: string, percentage: string|number, isIncrease: boolean}
// REQUESTS DEFAULT BODY
export const AGENT_REQUEST_DEFAULT_BODY = {
  data: {
    date_param: {
      operator: "[]",
      start: "",
      end: ""
    },
    heure_param: {
      operator: "[]",
      start: "",
      end: ""
    },
    key: ''
  }
} as AgentCommonRequestBody

export const AGENT_WITH_LAST_DATE_REQUEST_DEFAULT_BODY = {
  isLastDate: false,
  data: {
    dateDebut: "",
    dateFin: "",
    heureParam: {
      start: "",
      end: ""
    }
  }
} as AgentWithLastDateCommonRequestBody

export type InactivityKPI = {sumTotal1jr: string; sumTotal7jr: string; sumTotal30jr: string; data: Array<AgentInactivityItem & {gaugePercentage?: string}>}
export type CommissionKPI = {totalAgent: string; totalDistributeur: string; data: Array<CommissionItem & {gaugePercentage?: string}>}
export const COMMISSION_KPI_DEFAULT_VALUE = {
  totalAgent: '0',
  totalDistributeur: '0',
  data: []
}
